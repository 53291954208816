import React from "react";
import { Link } from "../../../../components/shared/actions";
import { LINKS } from "../../../../constants";
import "./style.css";

const FooterLink = ({ href, children }) => (
  <Link blank href={href} className="login-link-footer">
    {children}
  </Link>
);

const LINKS_DATA = [
  { href: LINKS.linkQuemSomos, label: "A Caixa Seguridade" },
  { href: LINKS.aCaixa, label: "Sobre a CAIXA" },
  {
    href: LINKS.politicaPrivacidadeVidaPrevidencia,
    label: "Política de Privacidade",
  },
  { href: LINKS.caixaNoticias, label: "CAIXA Notícias" },

  // adicione todos os seus links aqui
];

const FooterLogin = () => {
  return (
    <div className="login-footer">
      {/* <div className="flex-column flex-md-row"> */}
      <div className="login-links-footer flex-column flex-md-row">
        {LINKS_DATA.map((link) => (
          <FooterLink key={link.href} href={link.href} className="login-link-footer">
            {link.label}
          </FooterLink>
        ))}
      </div>

      <hr />

      <div className="infos-footer">
        <p>Caixa Vida e Previdência S/A - CNPJ 03.730.204/0001-76</p>
        <p>
        Av. Dr. Chucri Zaidan, 246, Edifício Riverview Corporate Tower, 12º andar
        </p>
        <p>São Paulo/SP - CEP: 04583-110</p>
      </div>
    </div>
  );
};
export default FooterLogin;
