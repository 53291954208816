import React, { useEffect, useState } from "react";
import { TextFieldMask } from "@cvp/design-system/react";
import { useHistory } from "react-router-dom";
import Logo from "../../../asserts/images/logo-caixa-vida-e-previdencia-branco.svg";
import IconLogin from "../../../asserts/images/icon-desbloquear.svg";
import { phoneToHref } from "../../../utils";
import "./style.css";

const FormLogin = (props) => {
  const {
    isCpfValid,
    cpfTitular,
    setCpfTitular,
    setShowModal,
    cpfSegurado,
    cpfBeneficiario,
    disabled,
    setDisabled,
    fetchData,
    loading,
    showMessage,
    message,
  } = props;
  const history = useHistory();
  
  const [cpfTitularError, setCpfTitularError] = useState(false);
  const [cpfConsultanteError, setCpfConsultanteError] = useState(false);

  function handleCpfTitularChange(e) {
    const cleanedValue = e.replace(/[\r\n]/g, "").replace(/[^\d]/g, '');
    setCpfTitularError(cleanedValue !== '' && !isCpfValid(cleanedValue, true));
  }

  function handleCpfConsultanteChange(e) {
    const cleanedValue = e.replace(/[\r\n]/g, "").replace(/[^\d]/g, '');
    setCpfConsultanteError(cleanedValue !== '' && !isCpfValid(cleanedValue));
  }

  function handleCheckboxChange() {
    setCpfTitular(!cpfTitular);
  }

  function handleModalOpen() {
    setShowModal(true);
  }

  function handleFetchData() {
    if (!disabled) {
      fetchData();
    }
  }

  function handleAcompanhamento() {
    history.push("/sinistro/acompanhamento");
  }

  useEffect(() => {
    const isCpfConsultanteValid = cpfBeneficiario === '' || !cpfConsultanteError;
    const isDisabled =
      cpfTitularError ||
      (!cpfTitular && (!isCpfConsultanteValid || cpfConsultanteError)) ||
      cpfSegurado.replace(/[^\d]/g, '').length !== 11;

    setDisabled(isDisabled);
  }, [cpfTitularError, cpfConsultanteError, cpfSegurado, cpfBeneficiario, cpfTitular, setDisabled]);

  return (
    <div className="login-page">
      <div className="logo">
        <Logo id="logo-image-login" />
      </div>
      <div className="first-title">
        <p className="acompanhar-login">Acompanhar Sinistro</p>
        <div className="faca-login">
          <IconLogin id="iconLogin" />
          <p className="second-title">
            Faça seu <strong>login</strong>
          </p>
        </div>
      </div>

      <div className="login-card">
        <div className="card-section">
          <div className="screen-description">
            <p>
              Aqui você pode acompanhar as etapas de um processo de sinistro
              aberto. Utilize o CPF do titular do seguro/previdência e CPF do
              consultante já cadastrados em nosso aplicativo.
            </p>
          </div>
          <div className="login-input-group">
            <div className="login-input-container">
              <p className="login-label">
                CPF do titular do seguro/previdência
              </p>
              <TextFieldMask
                style={{ border: "0px solid transparent" }}
                mask="cpf"
                placeholder="CPF: 000.000.000-00"
                value={cpfSegurado}
                onChange={handleCpfTitularChange}
                error={cpfTitularError}
              />
              {cpfTitularError && (
                <small className="text-danger">
                  CPF inválido ou incompleto
                </small>
              )}
            </div>

            {!cpfTitular && (
              <div className="login-input-container mt-3">
                <p className="login-label">CPF do consultante</p>
                <TextFieldMask
                  style={{ border: "0px solid transparent" }}
                  mask="cpf"
                  placeholder="CPF: 000.000.000-00"
                  value={cpfBeneficiario}
                  onChange={handleCpfConsultanteChange}
                  error={cpfConsultanteError}
                />
                {cpfConsultanteError && (
                  <small className="text-danger">
                    CPF inválido ou incompleto
                  </small>
                )}
              </div>
            )}

            <div className="form-check mt-1">
              <input
                className="form-check-input"
                style={{ marginTop: "6px" }}
                type="checkbox"
                checked={cpfTitular}
                onChange={handleCheckboxChange}
              />
              <small style={{ fontWeight: 200 }}>
                CPF do beneficiario igual ao Titular
              </small>
            </div>
          </div>

          <button
            type="button"
            className="login-button mt-4"
            style={{ background: disabled ? "#aaaaaa" : "#005fc8" }}
            onClick={handleFetchData}
            disabled={disabled}
          >
            {loading ? "Carregando..." : "Acessar"}
          </button>

          {showMessage && (
            <small className="mt-3 mb-3 text-danger">{message}</small>
          )}

          <div className="login-links">
            <button type="button" onClick={handleModalOpen}>
              Não tenho cadastro
            </button>
            <button
              type="button"
              onClick={handleAcompanhamento}
            >
              Desvincular cadastro
            </button>
          </div>
        </div>

        <div className="costumer-service">
          <p>
            Não foi possível acessar sua conta?
            <br />
            Fale com a gente pelo telefone{" "}
            <a className="phone-number" href={phoneToHref("0800 722 2492")}>
              <strong>0800 722 2492</strong>
            </a>
            <br />
            Atendimento das 8h às 18h de segunda a sexta-feira.
          </p>
        </div>
      </div>
    </div>
  );
};

export default FormLogin;
