import React, { useState,  useCallback } from 'react';
import FormLogin from './FormLogin';
import FooterLogin from '../sections/footer-login/FooterLogin';
import ModalLogin from '../sections/footer-login/ModalLogin';
import { useHistory } from 'react-router-dom';
import useSinisterTimeline from '../../../hooks/requests/useSinisterTimeline';
import { verifyCPF } from '../../../utils';
import './style.css';

export const LoginPage = () => {
  const [cpfSegurado, setCpfSegurado] = useState('');
  const [cpfBeneficiario, setCpfBeneficiario] = useState('');
  const [cpfTitular, setCpfTitular] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [disabled, setDisabled] = useState(true);
  const [showMessage, setShowMessage] = useState(false);
  const [message, setMessage] = useState('');
  const { verificarBeneficiarioSinistro, loading } = useSinisterTimeline();
  const [cpfError, setCpfError] = useState(false);
  const history = useHistory();

  const fetchData = useCallback(async () => {
    const cleanedCpfSegurado = cpfSegurado.replace(/[^\d]/g, '');
    const cleanedCpfBeneficiario = cpfBeneficiario.replace(/[^\d]/g, '');

    const result = await verificarBeneficiarioSinistro({
      cpfSegurado: cleanedCpfSegurado,
      cpfBeneficiario: cleanedCpfBeneficiario,
    });

    if (result?.dados.entidade) {
      history.push('/sinistro/acompanhar', { cpfTitular: cleanedCpfSegurado });
      return;
    }

    setMessage(result?.dados.mensagens[0].descricao);
    setShowMessage(true);
  }, [cpfSegurado, cpfBeneficiario, verificarBeneficiarioSinistro, history]);

  const isCpfValid = useCallback((inputNumbers, segurado) => {
    if (verifyCPF(inputNumbers)) {
      if (segurado) setCpfSegurado(inputNumbers);
      else setCpfBeneficiario(inputNumbers);
      return true;
    }
    return false;
  }, []);

  return (
    <>
      <FormLogin
        cpfBeneficiario={cpfBeneficiario}
        cpfError={cpfError}
        cpfSegurado={cpfSegurado}
        cpfTitular={cpfTitular}
        disabled={disabled}
        setDisabled={setDisabled}
        fetchData={fetchData}
        isCpfValid={isCpfValid}
        loading={loading}
        message={message}
        setCpfError={setCpfError}
        setCpfTitular={setCpfTitular}
        setShowModal={setShowModal}
        showMessage={showMessage}
      />
      <ModalLogin
        showModal={showModal}
        setShowModal={setShowModal}
        backdrop="static"
      />
      <FooterLogin />
    </>
  );
};

export default LoginPage;
